<template>
  <div class="yixiubao-join">
    <!-- <div class="header">
      <div @click="back" class="back">
        <img :src="require('@/assets/imgs/workbench/icon_arrow-left.svg')"/>
      </div>
      <span class="name">医修保</span>
    </div> -->
    <div class="content">
      <div class="panel shuzipuhui">
        <img :src="require('@/assets/imgs/workbench/icon_shuzipuhui.svg')" />
        <div class="intro">
          <b>尊敬的用户，</b>
          <div>为助力医学装备售后服务产业数字化转型，小紫平台特别推出【数字普惠计划】。面向医学装备市场小微企业提供【医修保基础版】免费服务。</div>
        </div>
      </div>
      <div class="introduce">
        <div v-for="(item, index) in list" :key="'yxb-'+index" class="box">
          <img :src="item.icon" class="icon" />
          <b class="title">{{ item.title }}</b>
          <div class="desc">{{ item.desc }}</div>
        </div>
      </div>
      <div class="panel yixiubao">
        <div class="xz-left">
          <div class="box-1">
            <img :src="require('@/assets/imgs/workbench/ic_yixiubao_logo.svg')" class="logo">
            <div class="title-box">
              <b>医修保基础版</b>
              <div>国内领先的sass售后管理服务平台</div>
            </div>
          </div>
          <div @click="playVideo" class="box-2">
            <img :src="require('@/assets/imgs/workbench/ic_play.svg')" />
            查看医修保操作视频
          </div>
          <div class="box-3">
            <div v-for="(item, index) in countList" :key="'his-data-'+index" class="data">
              <div class="count">{{ item.count }}<span class="unit">{{ item.unit }}</span></div>
              <span class="desc">{{ item.desc }}</span>
            </div>
          </div>
        </div>
        <img :src="require('@/assets/imgs/workbench/bg_yixiubao_yuan.svg')" class="right" />
      </div>
    </div>
    <div v-if="entryStatus.tenantServiceInfoStatus !== 'use' && !entryStatus.tenantServiceInfoOrderPayStatus && userInfo.adminFlag" class="footer">
      <el-checkbox v-model="checked">已阅读并同意</el-checkbox>
      <span
        v-for="(item, index) in protocol" :key="'prot-'+index"
        @click="toRead(item)" class="accord">《{{ item.name }}》</span>
      <div @click="toJoin" class="join">立即免费开通</div>
    </div>
    <!-- 服务协议、隐私条款 -->
    <el-dialog :title="prot.name" :visible.sync="dialogVisible" width="70vw">
      <div style="height: 50vh;">
        <iframe :src="prot.url" style="border:none" width="100%" height="100%"></iframe>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { openService } from '@/api/payment';
export default {
  components: {
  },
  computed: {
  },
  data () {
    return {
      prot: {},
      checked: false,
      dialogVisible: false,
      entryStatus: {},
      list: [
        {
          icon: require('@/assets/imgs/workbench/ic_bianjiebaoxiu.svg'),
          title: '便捷报修',
          desc: '一键扫码、在线填写报修两种方式，实现报修方式智能化',
        },
        {
          icon: require('@/assets/imgs/workbench/ic_liucheng.svg'),
          title: '流程透明',
          desc: '可视化管理服务流程，服务更透明',
        },
        {
          icon: require('@/assets/imgs/workbench/ic_fuwudanganzhuisu.svg'),
          title: '服务档案追溯',
          desc: '为设备售后建立专属的生命周期档案',
        },
        {
          icon: require('@/assets/imgs/workbench/ic_gexinghuaguanli.svg'),
          title: '个性化管理',
          desc: '根据不同客户需求，生成不同自定义工单、自定义工作流等模板',
        },
        {
          icon: require('@/assets/imgs/workbench/ic_juheshujudaping.svg'),
          title: '聚合数据大屏',
          desc: '数字大屏展示，实现自动化分析，帮助企业更有效的数据管理及运营',
        },
      ],
      countList: [
        {
          count: '10,000',
          unit: '名',
          desc: '超10,000名工程师的平台',
        },
        {
          count: '5,000',
          unit: '家',
          desc: '超5,000家服务商的选择',
        },
        {
          count: '2,000',
          unit: '家',
          desc: '超2,000家机构的信赖',
        },
        {
          count: '100,000',
          unit: '条',
          desc: '超100,000条知识库整合',
        },
      ],
      protocol: [
        {
          name: '服务条款',
          url: this.sourceUrl + 'xzyxbfwtk.html',
        },
        {
          name: '隐私协议',
          url: this.sourceUrl + 'xzyxbysxy.html',
        },
      ],
      videoUrl: 'https://api.yw.1xie.com/yxb-xc.mp4',
    };
  },
  mounted () {
    this.userInfo = JSON.parse(this.$local.get('userInfo')) || {};
    this.entryStatus = JSON.parse(this.$local.get('entryStatus')) || {};
    this.userInfo = JSON.parse(this.$local.get('userInfo')) || {};
  },
  methods: {
    back () {
      this.$router.back();
    },
    toRead (item) {
      this.prot = item;
      this.dialogVisible = true;
    },
    playVideo () {
      window.open(this.videoUrl, '_blank');
    },
    async toJoin () {
      if (!this.checked) {
        this.$message({
          message: '请阅读并勾选服务条款',
          type: 'warning',
        });
        return;
      }
      // 调开通接口
      await openService({
        productId: '3',
      });

      this.$message.success('已成功开通医修保');
      this.$tabs.items = [];
      this.$router.push({
        path: '/workbench',
      });
      window.location.reload();
    },
  },
};
</script>
<style lang="scss" scoped>
.yixiubao-join {
  .header {
    background-color: #ffffff;
    padding: 10px 5px;
    display: flex;
    align-items: center;
    .back {
      height: 32px;
      padding: 5px 15px;
      border-right: 1px solid #EBEDF0;
      > img {
        width: 20px;
        height: 20px;
      }
    }
    .name {
      padding-left: 15px;
      font-size: 16px;
      font-weight: 600;
    }
  }
  .content {
    color: #1F2733;
    font-size: 14px;
    padding: 0 0 75px;
    overflow-x: scroll;
    .panel {
      background-color: #ffffff;
      border-radius: 6px;
    }
    .shuzipuhui {
      display: flex;
      align-items: center;
      padding: 10px 20px;
      > img {
        width: 100px;
        height: 86px;
      }
      .intro {
        font-size: 14px;
        padding-left: 40px;
        > b {
          display: block;
          font-size: 16px;
          font-weight: 600;
          padding-bottom: 5px;
        }
      }
    }
    .introduce {
      padding: 40px 0 15px;
      display: flex;
      justify-content: space-between;
      flex-shrink: 0;
      .box {
        text-align: center;
        min-width: 80px;
        width: 18%;
        padding: 15px 15px 20px;
        background: #FFFFFF;
        border-radius: 4px;
        position: relative;
        .icon {
          position: absolute;
          top: -25px;
          left: 15px;
          width: 70px;
          height: 70px;
        }
        .title {
          display: block;
          text-align: left;
          font-size: 16px;
          font-weight: 500;
          padding-left: 70px;
          padding-bottom: 20px;
        }
        .desc {
          font-size: 14px;
          font-weight: 400;
          color: #929AA6;
        }
      }
    }
    .yixiubao {
      padding: 25px 35px;
      display: flex;
      .xz-left {
        width: 40%;
        min-width: 300px;
        padding: 30px;
        display: inline-flex;
        flex-direction: column;
        justify-content: space-between;
        flex-shrink: 0;
        .box-1 {
          display: inline-flex;
          align-items: center;
          .logo {
            width: 74px;
            height: 72px;
            margin-right: 10px;
          }
          .title-box {
            > b {
              font-size: 24px;
              font-weight: 600;
              padding-bottom: 10px;
            }
          }
        }
        .box-2 {
          cursor: pointer;
          width: 72%;
          > img {
            width: 14px;
            height: 14px;
            margin-right: 5px;
          }
          background: #237FFA;
          border-radius: 4px;
          text-align: center;
          padding: 10px 0;
          font-size: 14px;
          font-weight: 400;
          color: #FFFFFF;
        }
        .box-3 {
          display: inline-flex;
          flex-wrap: wrap;
          .data {
            width: 50%;
            padding-bottom: 30px;
            .count {
              padding-bottom: 6px;
              font-size: 24px;
              font-weight: 800;
              font-family: Bebas;
              color: #1A66FF;
              .unit {
                font-weight: normal;
                font-size: 18px;
                padding-left: 5px;
              }
            }
            .desc {
              font-size: 12px;
              font-weight: 400;
              color: #5F6A7A;
            }
          }
        }
      }
      .right {
        padding: 30px 0;
        width: 58%;
      }
      @media screen and (max-width: 1560px){
        .xz-left {
          padding: 0;
        }
      }
    }
  }
  .footer {
    position: fixed;
    background-color: #ffffff;
    bottom: 0;
    right: 0;
    width: calc(100% - 220px);
    padding: 15px 20px;
    text-align: right;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    .accord {
      color: #237FFA;
    }
    .join {
      margin-left: 10px;
      display: inline-block;
      background: #237FFA;
      padding: 5px 15px;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 400;
      color: #FFFFFF;
    }
  }
}
</style>
