import server from '../request';

/**
 * 获取付款产品列表
 * @returns Array
 */
export const getProductList = data => {
  return server.post('/xiaozi-xmb/wireless/saas/customer/expire/page', data);
};

/**
 * 创建订单
 * @param {string,string} param0
 * @returns
 */
export const createPaymentOrder = ({productId, productName, objId, objName, orderType})=> {
  return server.post('/xiaozi-xmb/wireless/saas/order/add', {productId, productName, objId, objName, orderType});
};

/**
 * 检查订单是否支付成功
 * @param {*} id
 * @returns
 */
export const checkIfPaySuccessed = (id)=> {
  return server.post('/xiaozi-xmb/wireless/saas/order/checkPay', {id}, {
    stopDefaultMessage: true,
    stopDefaultLoading: true,
  });
};

/**
 * 根据支付方式返回支付二维码
 * @param {*} param0
 * @returns
 */
export const getPayQRCode = ({id, payMethod})=> {
  return server.post('/xiaozi-xmb/wireless/saas/order/getQrCode', {id, payMethod});
};

/**
 * 获取订单列表
 * @param {*} param0
 * @returns
 */
export const getPayOrderList = ({code, productName, payStatus, invoiceStatus, invoiceStatusList, payStatusList, pageNum, pageSize})=> {
  return server.post('/xiaozi-xmb/wireless/saas/order/queryPage', {code, productName, payStatus, payStatusList, pageNum, pageSize, invoiceStatus, invoiceStatusList});
};

/**
 * 获取付款订单详情
 * @param {*} id
 * @returns
 */
export const getPayOrderDetail = (id)=> {
  return server.post('/xiaozi-xmb/wireless/saas/order/queryDetail', {id});
};

/**
 * 修改订单支付方式
 * @param {*} id
 * @returns
 */
export const changePayMethod = (data)=> {
  return server.post('/xiaozi-xmb/wireless/saas/order/changePayMethod', data);
};

/**
 * 上传订单支付凭证
 * @param {*}
 * @returns
 */
export const uploadPayVoucher = (data)=> {
  return server.post('/xiaozi-xmb/wireless/saas/order/uploadPayVoucher', data);
};

/**
 * 撤销支付凭证
 * @param {*} id
 * @returns
 */
export const revokePayVoucher = (data)=> {
  return server.post('/xiaozi-xmb/wireless/saas/order/revokePayVoucher', data);
};
  // 服务商列表接口
export const getServiceProvider = (data) => {
  return server.post('/xiaozi-xmb/wireless/saas/serviceProvider/page', data);
};

// 开通（试用）医修保
export const openService = (data) => {
  return server.post('/xiaozi-xmo/wireless/miniapp/tenant/openService', data);
};
